import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, TypesearchContainerWidgetSpec, Widget, WidgetSpec, WidgetType, isMenuWidget, isPageWidget, isTypeResultsWidget, reportWidgetRenderError } from "../../";
import { Site, WithId } from "@maxxton/cms-api";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { FlexboxOptions } from "../flexbox";
import { ResultOptions } from "../../widget";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { Tabs } from "./Tabs";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: any[];
    styleSelection: string;
    tabSize: string;
}

const TARGETS = ["tabs"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "tabs-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetTabs, "tabsWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetTabs, "tabsWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetTabs, "styleTab"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "tabSize",
                                label: getI18nLocaleObject(namespaceList.widgetTabs, "tabSize"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "contain-sized",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "fullWidth"),
                                    },
                                ],
                            },
                            {
                                variable: "styleSelection",
                                label: getI18nLocaleObject(namespaceList.widgetTabs, "styleSelection"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "tab-styled",
                                        label: getI18nLocaleObject(namespaceList.widgetTabs, "tabStyled"),
                                    },
                                    {
                                        value: "button-styled",
                                        label: getI18nLocaleObject(namespaceList.widgetTabs, "buttonStyled"),
                                    },
                                    {
                                        value: "text-styled",
                                        label: getI18nLocaleObject(namespaceList.widgetTabs, "textStyled"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export interface LocalizedChildOptions {
    locale: string;
    tabName: string;
}

export interface TabsChildOptions {
    localized?: LocalizedChildOptions[];
    name?: string;
    isPayingCustomer?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function tabsWidget(type: WidgetType) {
    const tabs: WidgetSpec<WidgetOptions> = {
        id: "tabs",
        type,
        widgetGroup: WidgetGroup.LAYOUT,
        name: getI18nLocaleObject(namespaceList.widgetTabs, "tabsWidget"),
        description: getI18nLocaleObject(namespaceList.widgetTabs, "tabsWidgetDescription"),
        optionsForm: widgetOptionsForm,
        childOptions: [
            {
                type: "statictabs",
                tabs: [
                    {
                        name: getI18nLocaleObject(namespaceList.widgetTabs, "tabsWidget"),
                        properties: [
                            [
                                localized({
                                    variable: "localized",
                                    tabContent: [
                                        {
                                            type: "text",
                                            variable: "tabName",
                                            label: getI18nLocaleObject(namespaceList.widgetTabs, "styleTab"),
                                            required: true,
                                        },
                                    ],
                                }),
                                {
                                    type: "checkbox",
                                    variable: "isPayingCustomer",
                                    label: getI18nLocaleObject(namespaceList.widgetTabs, "isPayingCustomer"),
                                },
                            ],
                        ],
                    },
                ],
            },
        ],
        defaultOptions: (): WidgetOptions => ({
            styleIds: [],
            styleSelection: "tab-styled",
            tabSize: "default",
        }),
    };
    (tabs as PageWidgetSpec<WidgetOptions> | TypesearchContainerWidgetSpec<WidgetOptions>).render = async (
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions,
        shouldReturnProps?: boolean,
        allSites?: Array<Site & WithId>,
        flexboxOptions?: FlexboxOptions
    ) => {
        let children: Array<{ element: JSX.Element; options: any; tabId: string }> = [];
        children = await Promise.all(
            widget.children.map((child, key) => {
                const childSpec = child.spec;
                if (isPageWidget(childSpec)) {
                    return childSpec
                        .render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions, shouldReturnProps, allSites, flexboxOptions)
                        .then((element) => ({ element, options: child.options, tabId: child._id, index: key }))
                        .catch((err) => {
                            reportWidgetRenderError(widget, err, childSpec, context);
                            return { element: <div key={key} />, options: child.options, tabId: child._id, index: key };
                        });
                } else if (isTypeResultsWidget(childSpec)) {
                    return childSpec
                        .render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions, flexboxOptions)
                        .then((element) => ({ element, options: child.options, tabId: child._id, index: key }))
                        .catch((err) => {
                            reportWidgetRenderError(widget, err, childSpec, context);
                            return { element: <div key={key} />, options: child.options, tabId: child._id, index: key };
                        });
                } else if (isMenuWidget(childSpec)) {
                    return childSpec
                        .render(child, context, allSites, sitemapPageLinkWidgetOptions, flexboxOptions)
                        .then((element) => ({ element, options: child.options, tabId: child._id, index: key }))
                        .catch((err) => {
                            reportWidgetRenderError(widget, err, childSpec, context);
                            return { element: <div key={key} />, options: child.options, tabId: child._id, index: key };
                        });
                }
                throw new TypeError("Expected child widgets to be page widgets");
            })
        );
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return (
            <Tabs
                widget={widget}
                context={context}
                sitemapPageLinkWidgetOptions={sitemapPageLinkWidgetOptions}
                resultOptions={resultOptions}
                dynamicContainerOptions={dynamicContainerOptions}
                shouldReturnProps={shouldReturnProps}
                allSites={allSites}
                widgetId={widget._id}
                options={widget.options}
                children={children}
                className={styleClasses}
                localeId={context.currentLocale.locale}
                flexboxOptions={flexboxOptions}
            />
        );
    };
    return tabs;
}
