import * as React from "react";
import * as classnames from "classnames";

import { AccommodationType, Unit } from "../../mxts";
import { LocalizedChildOptions, TabsChildOptions, WidgetOptions } from "./";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ResultOptions, Widget } from "../../widget";
import { Site, WithId } from "@maxxton/cms-api";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { ActionType } from "../../../redux/actions";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FlexboxOptions } from "../flexbox";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { Resort } from "@maxxton/cms-mxts-api";
import { ResultsPanelAction } from "../../../redux/actions/resultsPanelAction";
import { ResultsPanelState } from "../../../redux/reducers/resultsPanelReducer";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { wrapProps } from "../../../i18n";

interface Children {
    element: JSX.Element;
    options: TabsChildOptions;
    tabId: string;
    index?: number;
}
interface TabsBaseProps extends PageWidgetBaseProps<WidgetOptions> {
    children: Children[];
    localeId: string;
    widgetId: string;
    className?: string;
    accommodationType?: AccommodationType;
    resort?: Resort;
    unit?: Unit;
    rateTypeId?: number;
    unitBookUri?: string;
    dynamicFilter?: DynamicFilter;
    amenityCodes?: string[];
    dispatchOpenLinkedTabAction?: (tabLinkOption?: string) => void;
    widget: Widget<WidgetOptions>;
    context: CMSProvidedProperties;
    sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions;
    resultOptions?: ResultOptions;
    dynamicContainerOptions?: DynamicContainerOptions;
    shouldReturnProps?: boolean;
    allSites?: Array<Site & WithId>;
    flexboxOptions?: FlexboxOptions;
}

interface TabsBaseState {
    activeTab: number;
    disableWidget: boolean;
    children: Children[];
}

interface TabsDispatchProps {
    dispatchAction: Dispatch<ResultsPanelAction>;
}

interface TabsStoreProps {
    resultsPanelState: ResultsPanelState;
    dynamicFilter: DynamicFilter;
}

const DEFAULT_LOCALIZED_OPTIONS: LocalizedChildOptions = {
    tabName: "",
    locale: "",
};

type TabsProps = TabsBaseProps & TabsDispatchProps & TabsStoreProps;

class TabsBase extends React.PureComponent<TabsProps, TabsBaseState> {
    private tabRefs: { [key: string]: (NavLink & { onClick?: () => void }) | null };

    constructor(props: TabsProps) {
        super(props);
        this.state = {
            activeTab: 0,
            disableWidget: true,
            children: props.children,
        };
        this.tabRefs = {};
    }

    public componentDidUpdate(prevProps: TabsProps, prevState: TabsBaseState) {
        const { accommodationType, unit, resultsPanelState, dispatchAction, widgetId } = this.props;
        const { children } = this.state;
        if (prevState.children !== children) {
            this.linkTabOnPage();
        }
        const currentTab = document.getElementsByClassName("active nav-link");
        const item = currentTab.item(0);
        const name = item && item.getAttribute("name") ? item.getAttribute("name") : "";
        const image = [];
        for (let i = 1; i <= children.length; i++) {
            image.push(document.getElementsByName(name!.slice(0, -1) + i)[0]);
        }
        for (let i = 0; i < children.length; i++) {
            if (image[i]) {
                if (name === image[i]!.getAttribute("name")) {
                    image[i].parentElement!.setAttribute("style", "flex: 1");
                } else {
                    image[i].parentElement!.setAttribute("style", "flex: 0");
                }
            }
        }
        const isUnitSearch = unit?.unitId;
        const resourceId = isUnitSearch ? unit?.resourceId : accommodationType?.resourceId;
        const hasMatchedResourceId = resourceId === resultsPanelState.resourceId;
        const hasMatchedUnitId = hasMatchedResourceId && unit?.unitId === resultsPanelState.unitId;
        const preCondition = isUnitSearch ? hasMatchedUnitId : hasMatchedResourceId;
        const hasMatchedTabsWidgetId = resultsPanelState.tabsWidgetId && resultsPanelState.tabsWidgetId === widgetId;
        if (preCondition && hasMatchedTabsWidgetId && resultsPanelState.tabId) {
            const tab = this.tabRefs[resultsPanelState.tabId];
            if (tab?.onClick) {
                tab.onClick();
            }
            dispatchAction({
                type: ActionType.ClearResultsPanelOpenLinkedTabState,
            });
        }
    }

    public componentDidMount() {
        const { activeTab } = this.state;
        this.setState({ disableWidget: !isClientLoggedIn() });
        this.updatePayingCustomer(activeTab);
    }

    public render(): JSX.Element | null {
        const { className, options } = this.props;
        const { activeTab, children } = this.state;
        const sortedChildren = this.sortChildrenByIndex(children);
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        let tabStyles = "";
        let tabSize = "";
        if (options.styleSelection === "button-styled") {
            tabStyles = "button-styled";
        } else if (options.styleSelection === "tab-styled") {
            tabStyles = "tab-styled";
        } else if (options.styleSelection === "text-styled") {
            tabStyles = "";
        }

        if (options.tabSize === "contain-sized") {
            tabSize = "contain-sized";
        }
        return (
            <div className={`plugin plugin-tabs ${className} ${hideWidget} ${tabStyles}`}>
                <Nav tabs className={tabSize}>
                    {sortedChildren.map((children: Children, ind: number) => this.renderTab(children, ind))}
                </Nav>
                <TabContent activeTab={activeTab}>{!!children.length && children[activeTab] && this.renderTabContent(sortedChildren[activeTab].element, activeTab)}</TabContent>
            </div>
        );
    }
    private sortChildrenByIndex(allChilds: Children[]) {
        const sortedChildren = [...allChilds];
        return sortedChildren.sort((a: Children, b: Children) => (a.index || 0) - (b.index || 0));
    }

    private setNavLinkRef = (tabId: string) => (navLinkRef: NavLink | null) => {
        this.tabRefs[tabId] = navLinkRef;
    };

    private renderTab({ options, tabId }: Children, tabIndex: number) {
        const { site, currentLocale } = this.props.context;
        const tabName: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localized || [] })?.tabName || "";
        return (
            <NavItem key={tabIndex}>
                <NavLink className={classnames({ active: this.state.activeTab === tabIndex })} onClick={this.toggleTab(tabIndex)} name={`tab${tabIndex + 1}`} ref={this.setNavLinkRef(tabId)}>
                    {tabName}
                </NavLink>
            </NavItem>
        );
    }

    private renderTabContent(element: JSX.Element, tabIndex: number) {
        const { accommodationType, rateTypeId, unit, resort, unitBookUri, dynamicFilter, amenityCodes, dispatchOpenLinkedTabAction } = this.props;
        let clonedElement;
        if (accommodationType || unit || resort) {
            clonedElement = React.cloneElement(element, { accommodationType, rateTypeId, unit, resort, unitBookUri, dynamicFilter, amenityCodes, dispatchOpenLinkedTabAction });
        }
        return (
            <TabPane key={tabIndex} tabId={tabIndex}>
                {clonedElement || element}
            </TabPane>
        );
    }

    private toggleTab(tabIndex: number): () => void {
        return () => {
            const { activeTab } = this.state;
            if (activeTab !== tabIndex) {
                this.setState({ activeTab: tabIndex });
            }
            this.updatePayingCustomer(tabIndex);
        };
    }

    private linkTabOnPage() {
        const { activeTab, children } = this.state;
        const tabSearchKey = new URLSearchParams(window.location.search).get("tab")?.toLowerCase();
        if (tabSearchKey) {
            const tabIndex = children.findIndex((child) => child.options.name?.toLowerCase() === tabSearchKey);
            if (tabIndex >= activeTab) {
                this.setState({ activeTab: tabIndex });
            } else {
                children.map((childs) => {
                    const tabValue = childs.options.localized?.some((localeList) => localeList.tabName.toLowerCase() === tabSearchKey);
                    if (tabValue && childs.index) {
                        this.setState({ activeTab: childs.index });
                    }
                });
            }
        }
    }

    private updatePayingCustomer(tabIndex: number) {
        const { children } = this.state;
        const payingCustomerTabIndex = children.find((item) => item.options.isPayingCustomer)?.index;
        let isPayingCustomer = false;
        if (payingCustomerTabIndex === tabIndex) {
            isPayingCustomer = true;
        }
        if (this.props.dynamicFilter.isPayingCustomer !== isPayingCustomer) {
            const { dispatchAction } = this.props;
            const action = {
                type: ActionType.DynamicFilter,
                filter: dynamicFilterType.isPayingCustomer,
                payload: { isPayingCustomer },
            };
            dispatchAction(action);
        }
    }
}

function mapStateToProps(state: State): TabsStoreProps {
    return {
        resultsPanelState: state.resultsPanelState,
        dynamicFilter: state.dynamicFilter,
    };
}

function mapDispatchToProps(dispatch: Dispatch<ResultsPanelAction>): TabsDispatchProps {
    return { dispatchAction: dispatch };
}

export const Tabs = wrapProps<TabsBaseProps>(connect<TabsStoreProps, TabsDispatchProps>(mapStateToProps, mapDispatchToProps)(TabsBase));
